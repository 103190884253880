*{
    --primary-color: #ab1612;
    --secondary-color: #3a3E41;

    --header-background-color: #252525;
    --header-text-color: #e8ebee;

    --button-text-color: white;

    --poker-darkgreen-color: #0c622d;
    --poker-green-color: #0a893a;

    margin: 0;
}
