.body {
    background: url("../images/red-carpet.jpeg") no-repeat fixed;
    background-size: cover;
}

@media (max-width: 959px) {
    .body {
        overflow-y: auto !important;
    }
}

.pokerPageContainer {
    flex-grow: 1;
}

.confettiCanvas {
    position: fixed !important;
}
